import CView from '@/components/CView/CView.vue';
import { ERouteNames } from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HomeView',
	components: {
		CView
	},
	data() {
		return {
			surveysRouteName: ERouteNames.Surveys
		};
	}
});
